.my-drawer {
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.my-drawer .am-drawer-sidebar {
  background-color: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.my-drawer .am-drawer-sidebar .am-list {
  width: 300px;
  padding: 0;
}


div.scroll-view {

  margin: 0px;
  padding: 0px;
  overflow-y: scroll;
}

body {
  -webkit-overflow-scrolling: touch;
}
