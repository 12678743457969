.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  xbackground-color: #282c34;
  background-color: #FAFAFA;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  overscroll-behavior-y: none;
}


.ant-menu-item.horizonal-menuitem.ant-menu-item-only-child {

  color: black;
  padding-top: 10px;
  padding-bottom: 2px;
  border-bottom-color: inherit;
  border-bottom-width: 2px;
}

.ant-menu-item.horizonal-menuitem.ant-menu-item-only-child.ant-menu-item-selected {

  color: black;
  padding-top: 10px;
  padding-bottom: 2px;
  border-bottom-color: #000;
  border-bottom-width: 2px;
}

.ant-menu-item.horizonal-menuitem.ant-menu-item-only-child.ant-menu-item-active {

  color: #198FFF;
  padding-top: 10px;
  padding-bottom: 2px;
  border-bottom-color: inherit;
  border-bottom-width: 2px;
}

.ant-menu-item.horizonal-menuitem.ant-menu-item-only-child.ant-menu-item-active.ant-menu-item-selected{

  color: black;
  border-bottom-color: #000;
  padding-top: 10px;
  padding-bottom: 2px;
  border-bottom-width: 2px;
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}

#root {

  height: 100%;
  width: 100%;
}
