.quote-cat {

  margin-top: 4px;
  margin-bottom: 4px;
}

.data-check-count {

  vertical-align: middle;
  line-height: normal;
  padding-top: 8px;
}
